import * as React from "react";
import ProjectSection from "../components/ProjectSection";
import { Link } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import ClientsSection from "../components/home/ClientSection";
import Banner from "../components/Banner";
// import KnowMoreSection from "../components/KnowMoreSection";
import Faq from "../components/Faq";
import { Seo } from "../components/seo";
import LetsConnect from "../components/LetsConnect";
import KnowAbout from "../components/KnowAbout";
import WhatWeAre from "../components/WhatWeAre";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
const Questions = [
  "Which industries does your UI UX Company serve?",
  "How do you approach a UI UX design project?",
  "What makes Octet stand out as the best UI Design Agency?",
  "What is the standard timeline for a UI UX design project?",
  "Can your UX Design Agency in Chicago create web and mobile apps?",
];

const SuratPage = () => {
  const banner = {
    title: `UI UX Design Agency Chicago`,
    content: "Octet Design Studio in Chicago is a prominent UI UX Design Agency known for designing and developing sophisticated, user-centric digital experiences.",
    img: ["why-octet-surat.webp"],
    imageAlt: "Octet Design Studio- UI UX Design Agency in Chicago",
    imageTitle: "UI UX Design Company, Chicago",
  };
  const projectData = [
    {
        title: "Ship Delight",
        desc: "An E-Commerce Logistic Website",
        Industry: "Logistics",
        firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
        firstTitle: "Real Time Shipment Status",
        secondAlt: "UI Dashboard To Check The Performance Status",
        secondTitle: "NDR Status",
        WhatWeDid: [
          {
            title: "Branding",
            link: "/branding/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "Wordpress Development",
            link: "/wordpress-development/",
          },
        ],
        link: "/project/shipdelight/",
        images: ["ship-delight-web.webp", "ship-delight-web-2.webp"],
    },
    {
        title: "KYSS",
        desc: "An accounting platform for MSME's",
        Industry: "FinTech",
        firstAlt: "UI UX Mobile Screen Of Accounting Platform",
        firstTitle: "Landing Page",
        secondAlt: "Past Invoice Details",
        secondTitle: "All Invoices",
        WhatWeDid: [
          {
            title: "Branding",
            link: "/branding/",
          },
          {
            title: "User Research",
            link: "/user-research/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
        ],
        link: "/project/kyss/",
        images: ["kyss.webp", "kyss-2.webp"],
    },
  ];
  const faqData = [
    {
      para: [
        `Our best UI UX design company in Chicago has experience in various industries, including <a href='/healthcare-design-agency/' title="healthcare" target='_blank'>healthcare</a>, <a href='/fintech-design-agency/' title="fintech" target='_blank'>fintech</a>, e-commerce, <a href='/edtech-design-agency/' title="edtech" target='_blank'>edtech</a>, and more. Our versatile team can adapt to your industry needs to deliver tailored design solutions.`,
      ],
    },
    {
      para: [
        `Octet, the best UI UX design company in USA, collaborates closely with clients to understand their goals, target audience, and project requirements. We ensure a user-centric design that aligns with their objectives. Our top UX design company approach involves thorough <a href='/user-research/' title="user research" target='_blank'>user research</a>, user analysis, wireframing, <a href='/rapid-prototyping/' title="prototyping" target='_blank'>prototyping</a>, visual design, and rigorous testing. `,
      ],
    },
    {
      para: [
        `Our team of exceptional designers, proactive approach, and dedication to innovation make our agency stand out. Our commitment to user-centered design concepts and in-depth market knowledge make us a reliable design partner.`,
      ],
    },
    {
      para: [
        `The timeline for a UI UX design project varies depending on its complexity and scope. We collaborate closely with our clients to develop project milestones and schedules that meet their requirements. Smaller projects often take a few weeks, whereas larger projects take a few months.`,
      ],
    },
    {
      para: [
        `Yes, we are known for being the best UI UX design agency. Our UI UX designers are well-versed in responsive design principles, so your digital products will perform effortlessly across various devices and screen sizes.`,
      ],
    },
  ];
  const lastLength = projectData;
  const info = {
    title: "Build your Next Project with our UI Agency",
    link: "/contact-us/",
    text: "Contact Us",
  };
  const whatWeAreData = {
    title: "Drive Success with our UX Design Agency",
    des: "Our UX design agency’s skilled team in Chicago seamlessly combines creativity and functionality to produce visually appealing designs that focus on user delight.",
    items: [
      {
        title: "User Research",
        content:
          "Our research-driven approach enables us to create tailored interfaces that align with your business objectives. As the best UI UX design company in USA, we enhance online visibility and user experience.",
        link: "/user-research/",
        linkText:
          'Our <a href="/user-research/" title="User Research Agency">user research agency</a> develops strategies to enhance digital visibility and user experience.',
      },
      {
        title: "UI UX Designing",
        content:
        "Octet is the best UI UX design company in USA, offering efficient, streamlined solutions that bring your digital vision to life. We fuse originality and functionality to design attractive interfaces providing engaging user experiences.",
        link: "/ui-ux-designing/",
        linkText:
          'Our <a href="/ui-ux-designing/" title="UI UX design agency">UI UX Design Agency</a>, has a plethora of experience providing customized solutions for various industries.',
      },
      {
        title: "Usability Testing",
        content:
          "We use advanced usability metrics to identify user pain points accurately and fine-tune the interface elements for more promising engagement. Our best UX design agency in Chicago employs advanced techniques to optimize your user experiences.",
        link: "/usability-testing/",
        linkText:
          'Our <a href="/usability-testing/" title="Usability Testing Agency">usability testing company</a> uses improved procedures to ensure your user experiences are well-optimized.',
      },
      {
        title: "UI Development",
        content:
          "We craft dynamic, engaging platforms that captivate users and increase their sessions. As Chicago’s best UI design agency, we craft custom user interfaces using design principles, programming languages, and core industry expertise.",
        link: "/ui-development/",
        linkText:
          'Our <a href="/ui-development/" title="UI Development Company">UI Development Company</a> creates engaging experiences by putting user-centric design upfron.',
      },
    ],
    mainLink: {
      link: "/services/",
      text: "View All UI UX Services",
    },
  };
  const faqDes = `We've compiled frequently asked questions to provide quick and informative answers about our UI UX company, web design agency in Chicago, the creation process, and pricing. If you still have any questions, don’t hesitate to <a href="/contact-us/" title="Contact Us"> contact us</a>.`;
  const knowAboutDes = [
    "Octet is Chicago’s best UI UX design agency with over years of experience transforming SaaS, B2B, and enterprise-grade products. Our proactive approach simplifies complex applications, delivering intuitive, user-centric solutions across diverse industries. Being the top UI UX design company in USA, we operate with an agile process that empowers businesses to unlock their potential and create functional, visually appealing products.",
  ];
  const knowAboutTitle = "Work with the Best UI UX Design Agency";
  const commonContent = {
    title: "Choose Better with the Best UI UX Design Agency",
    para: `At our top UI UX company, we are dedicated to creating visually appealing and user-centric solutions that enhance your brand's digital visibility.`,
    content: [
      {
        title: "Cross-Platform Adaptability",
        text: "We prioritize responsive designs that work seamlessly across all devices and screen sizes, enhancing user accessibility. We are your go-to UX design agency in Chicago, aiming to deliver exceptional UI UX solutions tailored to your needs.",
      },
      {
        title: "User-Centric Approach",
        text: "We adopt a user-centric approach to product development and prioritize their needs and preferences. Our UI UX company involves customizing the product to meet the target market's expectations and ensure customer satisfaction.",
      },
      {
        title: "Continuous Improvement",
        text: "Our design methodology is based on a continuous improvement process informed by extensive user feedback & market data. Our UX agency in Chicago ensures that our designs align with user behavior & remain responsive.",
      },
      {
        title: "Increased Conversion Rates",
        text: "Our best UI agency approaches to design optimization are grounded in data-driven insights and proven strategies. This results in a website that effectively engages and converts its audience.",
      },
    ],
  };
  const commonContent1 = {
    title: "Design Process by our UI UX Design Agency",
    para: ['Our research-driven UI UX design agency has transformed businesses by creating customer-centric solutions that allow them to serve millions of people.'],
    content: [
      {
        title: "1. Briefing",
        text: "To establish the nature of our collaboration, our UI UX design company in Chicago carries out design audits, competitive analyses, discussions, and usability testing that clarify product and business difficulties.",
      },
      {
        title: "2. Defining",
        text: "We determine your target market by interacting with your customers and utilizing various techniques to grasp their issues fully. As a team, we define the project, set deadlines, write down objectives, and decide on metrics to help you succeed.",
      },
      {
        title: "3. Strategizing",
        text: "Our UI agency strategizes the user experience flow, develops guidelines, and structures information architecture to organize your website's content and effectively achieve its purpose.",
      },
      {
        title: "4. Sketching",
        text: "As the USA’s best UX design agency, we formulate an effective visual hierarchy for your app or website to facilitate comprehension. We aim to provide you with the best UI UX design solutions that enhance the user experience and maximize your product's potential.",
      },
      {
        title: "5. Designing & Testing",
        text: "After finalizing the layout and designs, we develop a prototype and test the end product's functionality. This enables our UI UX design agency to evaluate the final product, ensuring it meets the required standards and specifications",
      },
    ],
  };
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "Which industries does your UI UX Company serve?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our best UI UX design agency in Chicago has experience in various industries, including healthcare, finance, e-commerce, education, and more. Our versatile team can adapt to your industry needs to deliver tailored design solutions."
                  }
                },{
                  "@type": "Question",
                  "name": "How do you approach a UI UX design project?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Octet, the best UI UX design company in USA, collaborates closely with clients to understand their goals, target audience, and project requirements. We ensure a user-centric design that aligns with their objectives. Our top UX design company approach involves thorough research, user analysis, wireframing, prototyping, visual design, and rigorous testing."
                  }
                },{
                  "@type": "Question",
                  "name": "What makes Octet stand out as the best UI Design Agency?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our team of exceptional designers, proactive approach, and dedication to innovation make our agency stand out. Our commitment to user-centered design concepts and in-depth market knowledge make us a reliable design partner."
                  }
                },{
                  "@type": "Question",
                  "name": "What is the standard timeline for a UI UX design project?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The timeline for a UI UX design project varies depending on its complexity and scope. We collaborate closely with our clients to develop project milestones and schedules that meet their requirements. Smaller projects often take a few weeks, whereas larger projects take a few months."
                  }
                },{
                  "@type": "Question",
                  "name": "Can your UX Design Agency in Chicago create web and mobile apps?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we are known for being the best UI UX design agency. Our UI UX designers are well-versed in responsive design principles, so your digital products will perform effortlessly across various devices and screen sizes."
                  }
                }]
              }                                                          
          `}
        </script>
      </Helmet>
      <Layout>
        <Banner
          content={banner}
          short={true}
          page={"ahmedabad-home"}
          location="Office no. 227, Green Palladia, Near Palanpur Canal Rd,
            Palanpur Jakatnaka, 
            Surat, Gujarat 395005
        "
        />
        <WhatWeAre data={whatWeAreData} />
        <KnowAbout
          des={knowAboutDes}
          title={knowAboutTitle}
          imageAlt={"Logo of UI UX Design Agency in Chicago"}
          imageTitle={"UI UX Design Agency in Chicago's Logo"}
        />
        <CommonServiceComponent data={commonContent} mode={"grey"} nopara />
        <CommonServiceComponent
          data={commonContent1}
          mode={"light"}
          list="single"
        />
        <section className="project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] pt-[100px] pb-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          <div className="container">
            <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                Successful Projects with <span className="h1-span">our UI UX Design Agency</span>
            </h2>
          </div>
          {projectData.map((data, index) => (
            <div key={index}>
              <ProjectSection num={index} lastLength={lastLength} data={data} />
            </div>
          ))}
          <div className="container lg:mt-[-100px] md:mt-[-35px] mt-[-40px]">
            <Link
              title="UI UX Design Portfolio"
              to="/projects/"
              className="animated-link font-medium font-heading text-[20px] md:text-[22px] lg:text-[30px] md:leading-[1.33] leading-[normal] tracking-[.05em] flex items-center gap-[32px] md:gap-[38px]"
            >
              <p className="max-w-[262px] md:max-w-none w-auto font-heading">
                See more work by our UI UX design agency{" "}
              </p>
              <span>
                <LinkArrowSvg />
              </span>
            </Link>
          </div>
        </section>
        <ClientsSection title={'Trusted by Clients Worldwide'} />
        <LetsConnect data={info} />
        <Faq section Questions={Questions} faqData={faqData} des={faqDes} />
        {/* <KnowMoreSection nin="Contact Us" Include="services" /> */}
      </Layout>
    </>
  );
};

export default SuratPage;

export const Head = () => (
  <Seo
    title="UI UX Design Agency - Chicago"
    description="Our Chicago-based UI UX design agency specializes in creating high-purpose user interfaces and strategic user experiences for brands & organizations."
  />
);
